<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Preplan Incidents</h4>
            <!-- Incidents table -->
            <IncidentsListing/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main';
import appConfig from '@/app.config';
import IncidentsListing from '@/components/incidents/incidents-listing';

export default {
  page: {
    title: 'Preplan Incidents',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    IncidentsListing,
  },
  data() {
    return {
      //
    };
  },
};
</script>
